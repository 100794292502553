.title,
.subtitle {
  text-align: center;
}

.title {
  margin-bottom: .3em;
}

.subtitle {
  margin-bottom: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  padding: 0 10px;
}

.form > label:not(:last-child) {
  margin-bottom: 25px;
}

.form > label:nth-last-child(3) {
  margin-bottom: 10px;
}

.submitButton {
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .title {
    font-size: 38px;
  }

  .subtitle {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .form > label:not(:last-child) {
    margin-bottom: 25px;
  }

  .form > label:nth-last-child(3) {
    margin-bottom: 15px;
  }

  .form {
    padding: 0 75px;
    margin-bottom: 40px;
  }
}
