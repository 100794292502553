.clickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ballContainer {
  position: relative;

  height: 100px;
}

.ball {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 183px;

  cursor: pointer;
}

.ball img {
  width: 100%;
}

.ball:active {
  transition: transform .05s ease;
}

.ball:active {
  transform: scale(.95);
}

.text {
  position: absolute;
  user-select: none;

  font-size: 47px;
  font-weight: 600;
}

.cigaretteImage {
  width: 41px;
}

@media screen and (min-height: 660px) {
  .ballContainer {
    height: 130px;
  }

  .ball {
    width: 232px;
  }

  .text {
    font-size: 60px;
  }

  .cigaretteImage {
    width: 43px;
  }
}

@media screen and (min-width: 1024px) {
  .cigaretteImage {
    width: 50px;
  }
}

@media screen and (max-height: 650px) {
  .cigaretteImage {
    width: 40px;
  }
}
