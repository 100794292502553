.title {
  margin-bottom: 13px;
  text-align: center;
}

.subtitle {
  margin-bottom: 10px;

  font-size: 16px;
  line-height: calc(27 / 22);
  text-align: center;
}

.vipText {
  margin-bottom: 20px;

  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
}

.form > *:not(:last-child) {
  margin-bottom: 20px;
}

.submitButton {
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .title {
    font-size: 38px;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 22px;
  }

  .vipText {
    font-size: 42px;
  }

  .form {
    padding: 0 30px;
  }
}
@media screen and (max-width: 1366px) {
  .title {
    font-size: 26px;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 16px;
  }

  .vipText {
    font-size: 28px;
  }
}

.Screen_screenWrapper__x3AzH {
  padding-top: 2.7vh;
}













