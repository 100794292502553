.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, .5);
  -webkit-backdrop-filter: blur(4px);
  opacity: 0;
  pointer-events: none;
  transition: opacity .1s;
  overflow-y: auto;
}

.wrapper.isActive {
  opacity: 1;
  pointer-events: all;
}

.modal {
  width: 100%;
  max-width: 594px;
  padding: 10px;
}