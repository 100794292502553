.container {
  width: 100%;
  max-width: 522px;
  padding: 50px 35px;
  margin: 0 auto 10vh;

  text-align: center;
  border: 1px solid rgba(112, 112, 112, .77);
  border-radius: 9px;

  backdrop-filter: blur(10px);
}

.dashedBox {
  padding: 20px;

  border: 3px dashed #4666FF;
}

.title {
  font-size: 26px;
  font-weight: 600;
}

@media screen and (min-width: 1366px) {
  .title {
    font-size: 37px;
  }
}