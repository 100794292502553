.title {
  margin-bottom: 36px;

  font-size: 90px;
  font-family: 'you-decide', sans-serif;
  font-weight: 300;
  text-align: center;
}

.subtitle {
  font-size: 22px;
  text-align: center;
  margin-bottom: 28px;
}

.secondTitle {
  margin-bottom: 25px;

  font-size: 34px;
  line-height: 1;
  font-weight: 600;
  text-align: center;
}

.cigaretteImage {
  position: absolute;
  bottom: 77px;

  width: 24vh;
  left: 18vw;
}

.link {
  display: block;
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .title {
    font-size: 110px;
  }

  .subtitle {
    font-size: 25px;
  }

  .secondTitle {
    font-size: 56px;
    font-weight: normal;
  }
}
