.title {
  font-size: 54px;
  margin-bottom: 5px;
}

.subtitle {
  margin-bottom: 30px;

  font-size: 18px;
  text-align: center;
}

.scoreBox {
  width: 100%;
  padding: 20px 20px 10px;
  margin-bottom: 40px;

  text-align: center;
  border: 3px dashed #4666FF;
}

.scoreBoxTitle {
  font-size: 26px;
}

.scoreBoxNumber {
  font-size: 63px;
}

.restrictionText {
  width: 100%;
  padding: 5px 0;
  margin-bottom: 20px;

  text-align: right;
}

.footer {
  margin-top: 40px;
}

.footer p {
  display: none;
}

.homeIcon,
.playAgainButton {
  margin-bottom: 15px;
}

.homeIcon img {
  width: 40px;
  height: 40px;
}

@media screen and (min-width: 1024px) {
  .title {
    font-size: 51px;
  }

  .subtitle {
    font-size: 25px;
  }

  .scoreBoxTitle {
    font-size: 38px;
  }

  .scoreBoxNumber {
    font-size: 93px;
  }

  .footer {
    margin-top: auto;
  }
}
