:root {
  --color-white: #fff;
  --color-violet-1: #5B309F;
  --color-violet-2: #8C41FF;
  --color-violet-3: #9638BF;
  --gradient-violet: linear-gradient(to bottom, #5F339F, #24153B);
  --color-red: #FF0000;
  --app-height: 100%;
}

@font-face {
  font-family: 'gad';
  src: url(./assets/fonts/Gad-DemiBold.otf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'gad';
  src: url(./assets/fonts/Gad-Medium.otf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'you-decide';
  src: url(./assets/fonts/YouDecideN-Layer_One.otf);
  font-display: swap;
}

@font-face {
  font-family: 'orbitron';
  src: url(./assets/fonts/Orbitron-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'orbitron';
  src: url(./assets/fonts/Orbitron-Bold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'orbitron';
  src: url(./assets/fonts/Orbitron-Black.ttf);
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'haim';
  src: url(./assets/fonts/Guttman_Haim.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'haim-condensed';
  src: url(./assets/fonts/Guttman_Haim-Condensed.ttf);
  font-display: swap;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  direction: rtl;
  height: 100%;
}

body {
  height: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  font-family: 'gad', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  background-color: #000;
  color: var(--color-white);
}

input {
  font-family: inherit;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 26px;
}

p {
  font-size: 14px;
}

.App {
  height: 100%;
}

a,
a:visited {
  color: var(--color-white);
}

.blue-text {
  /*font-family: "orbitron", sans-serif;*/
  font-weight: 900;
  font-size: unset;
  color: #6aabff;
}

select{-webkit-appearance: none;}

@media screen and (min-width: 1024px) {
  h1 {
    font-size: 32px;
  }

  p {
    font-size: 16px;
  }
}