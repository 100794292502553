.label {
  position: relative;
}

.label span {
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;

  height: 12px;
  width: 12px;

  border-top: 1px solid #3B2163;
  border-left: 1px solid #3B2163;
  transform: rotate(-135deg);
  transition: all .15s ease;
}

.label select:focus + span {
  transform: rotate(45deg);
}

.label option,
.label select {
  font-family: "gad", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}