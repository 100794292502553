.footer {
  position: relative;

  margin-top: auto;
  padding: 0 10px;

  font-family: 'haim-condensed', sans-serif;
  color: #000;
  text-align: center;
  font-size: 21px;

  background-color: var(--color-white);
  border: 10px solid #000;
  outline: 2px solid var(--color-white);
}

.footerTitle {
  text-decoration: underline;
  font-size: 40px;
}

.footer p {
  font-size: 30px;
  font-weight: bold;
}

@media screen and (min-width: 1024px) {
  .footer {
    font-size: 40px;
    color: #333;
  }

  .footerTitle {
    font-size: inherit;
    display: inline;
    margin-left: 10px;
  }

  .footer p {
    /*display: inline;*/
    font-size: inherit;
  }
}

@media screen and (min-width: 1366px) {
  .footer {
    font-size: 80px;
  }
}
