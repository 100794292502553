.label {
  position: relative;
  display: block;
}

.input {
  width: 100%;
  padding: .68em .62em .43em;

  font-size: 14px;
  color: var(--color-violet-1);

  border: 2px solid transparent;
  background-color: var(--color-white);
  transition: border-color .15s ease;
}

.input:focus,
.label:focus-within .input {
  border-color: var(--color-violet-2);
  outline: none;
}

.input::placeholder {
  color: inherit;
}

.errorMessage {
  position: absolute;
  top: calc(100% + 3px);

  color: var(--color-red);
  font-size: 13px;
}

@media screen and (min-width: 1024px) {
  .input {
    font-size: 16px;
  }
}
