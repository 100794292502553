.timerText {
  font-size: 50px;
  color: var(--color-white);
  font-family: 'orbitron', sans-serif;
}

.statusText {
  font-size: 16px;
}

.clicker {
  position: absolute;
  bottom: -45px;
}

.screen {
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .screen {
    overflow: unset;
    position: unset;
  }
}

@media screen and (min-width: 1024px) {
  .statusText {
    font-size: 25px;
  }

  .clicker {
    bottom: -150px;
  }
}

@media screen and (min-width: 1024px) and (min-height: 1000px) {
  .clicker {
    bottom: 80px;
  }
}

@media screen and (min-width: 1366px) and (min-height: 900px) {
  .clicker {
    bottom: 0;
  }
}