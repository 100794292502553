.timerText {
  font-size: 50px;
  color: var(--color-white);
  font-family: 'orbitron';
}

@media screen and (min-width: 1024px) {
  .timerText {
    font-size: 70px;
  }
}
