.realDateInput::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  opacity: 0;
}

.realDateInput {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  opacity: 0;
}