.screen {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.screen.isLoading::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, .6);
  background-image: url("../../assets/images/spinner.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: 0 0;
}

.screenInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.screenWrapper {
  width: 100%;
  height: inherit;
  max-width: 594px;
  padding: 7.7vh 20px 0;
  margin: 0 auto;
  /* flex: 1; */
}

@media screen and (min-width: 1024px) {
  .background {
    object-position: center;
  }

  .screenWrapper {
    padding-top: 5.7vh;
  }

  .screen {
    height: unset;
  }

  /*.screenInner {*/
  /*  height: 80vh;*/
  /*}*/

  .screenWrapper {
    height: 95vh;
  }
}
@media screen and (max-width: 1366px) {
  .screenWrapper {
    margin-bottom: 80px;
    padding-top: 2.7vh;
  }
}