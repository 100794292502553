.blurredBlock {
  padding: 38px 20px 27px;

  text-align: center;

  backdrop-filter: blur(34px);
  border: 1px solid rgba(112, 112, 112, .77);
}

.blurredBlock > *:not(:first-child) {
  margin-top: 25px;
}

@media screen and (min-width: 1024px) {
  .blurredBlock {
    font-size: 18px;
  }
}