.checkbox {
  position: relative;
  font-size: 14px;
  line-height: 1.5;
}

.checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.checkbox span {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-left: 10px;

  background-color: #fff;
  border-radius: 50%;
  vertical-align: middle;
}

.checkbox span::after {
  content: '';

  position: absolute;
  display: none;
  width: calc(100% - 6px);
  height: calc(100% - 6px);

  background-color: var(--color-violet-3);
  border-radius: inherit;
  transform-origin: center;
}

.checkbox input:checked + span::after {
  display: block;
  animation: checkbox-checked .15s ease forwards;
}

.error {
  color: var(--color-red);
  font-size: 13px;
}

@keyframes checkbox-checked {
  from {
    transform: scale(.1);
  }
  to {
    transform: scale(1);
  }
}

@media screen and (min-width: 1024px) {
  .checkbox {
    font-size: 16px;
  }
}
