.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
}

.footer a:nth-of-type(1) {
  margin-left: 34px;
}

.footer p {
  width: 100%;
  margin-top: 10px;
  line-height: calc(23 / 14);
  font-size: 14px;
}

@media screen and (min-width: 1024px) {
  .footer {
    margin-top: auto;
  }
  .footer p {
    white-space: nowrap;
  }
}
