.button {
  position: relative;
  min-height: 45px;
  width: 100%;
  max-width: 400px;
  padding: .79em 0;

  font-family: 'gad', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;

  background: var(--gradient-violet);
  border: 1px solid #fff;
  border-radius: 10px;
  cursor: pointer;

  transition: transform .15s ease;
}

.button:disabled {
  opacity: .8;
  cursor: default;
  pointer-events: none;
}

.bounceAnimation:active {
  transform: scale(.97);
}

.button a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@media screen and (min-width: 1024px) {
  .button {
    font-size: 16px;
  }
}