.icon {
  position: absolute;
  top: -3px;
  right: -15px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: calc(100% + 6px);
  width: 49.5px;

  background: inherit;
  border-radius: 50%;
  border: 1px solid #fff;
}

.popup {
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0;
  z-index: 10;

  padding: 5px;

  background-color: #fff;
  border-radius: 4px 4px 0 4px;
  opacity: 0;
  pointer-events: none;
  transition: all .15s;
}

.popup.isActive {
  opacity: 1;
  pointer-events: all;
}

.popup::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 0;

  width: 0;
  height: 0;

  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: #ffffff transparent transparent transparent;
}

.popupList {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.popupList li:not(:last-child) {
  margin-left: 5px;
}

@media screen and (min-width: 1024px){
  .icon {
    width: 54px;
  }
}
